import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, StaticQuery } from 'gatsby';
import Link from 'gatsby-link';
import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share';

import { useClickIdEnhancedLink } from '../../lib/tracking';

const SupportMe = ({ nordVPNLink, nordPassLink }) => {
  const enhancedNordVPNHref = useClickIdEnhancedLink(nordVPNLink);
  const enhancedNordPassHref = useClickIdEnhancedLink(nordPassLink);

  return (
    <StaticQuery query={query}>
      {({
        site: {
          siteMetadata: { siteUrl },
        },
      }) => (
        <>
          <h1 className="mb-6">How to support me</h1>

          <h2>1. Buy NordVPN or NordPass using my links</h2>
          <div className="flex flex-row mt-2 text-center">
            <a
              href={enhancedNordVPNHref}
              className="btn btn-secondary flex-1 mr-4"
            >
              NordVPN
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
            </a>
            <a href={enhancedNordPassHref} className="btn btn-secondary flex-1">
              NordPass
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
            </a>
          </div>

          <h2 className="mt-6 mb-2">2. Share this with a friend</h2>
          <div className="flex justify-around">
            <FacebookShareButton
              url={`${siteUrl}/cybersecurity-live-quiz`}
              hashtag="#cybersecuritygame"
            >
              <FacebookIcon round />
            </FacebookShareButton>
            <LinkedinShareButton
              url={`${siteUrl}/cybersecurity-live-quiz`}
              source="You are safe online"
              title="Cyber-security quiz"
            >
              <LinkedinIcon round />
            </LinkedinShareButton>
          </div>

          <p className="mt-8 text-primary mb-2">
            You can read my affiliation with NordVPN, how this works, and see
            more support options here:
          </p>
          <Link className="btn btn-primary text-center" to="/support">
            Support page
          </Link>
        </>
      )}
    </StaticQuery>
  );
};

export default SupportMe;
const query = graphql`
  query SupportMeQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
