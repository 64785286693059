import {
  faBrain,
  faCheckCircle,
  faSpinner,
  faTimesCircle,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { animated, useSpring, useTransition } from 'react-spring';
import { useMeasure } from 'react-use';

import Arrow from '../../images/quiz/arrow.inline.svg';
import { wait } from '../../lib';
import getFirebase from '../../lib/firebase';
import { trackGTMEvent } from '../../lib/gtm';

function formatNumber(number) {
  const order = Math.max(0, Math.ceil(-Math.log10(50000))) + 2;

  return (number ?? 0).toFixed(order);
}

const SUCCESS_ANIMATION_DURATION = 2000;
const START_GAME_DELAY = 2000;

const Quiz = ({ globalTotalPoints, openHelpModal, openSupportModal }) => {
  const [gameKey, setGameKey] = useState(0);
  const [question, setQuestion] = useState();
  const [multiplier, setMultiplier] = useState(1);
  const [total, setTotal] = useState(0);
  const [done, setDone] = useState(false);
  const [explanation, setExplanation] = useState(undefined);
  const [wrongAnswers, setWrongAnswers] = useState({});
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [loadingAnswer, setLoadingAnswer] = useState(null);

  useEffect(() => {
    let cancelled = false;

    (async () => {
      const {
        data: { done, multiplier, question, total },
      } = await getFirebase()
        ?.functions('us-central1')
        .httpsCallable('startGame')();

      if (cancelled) return;
      await wait(START_GAME_DELAY);
      if (cancelled) return;

      setDone(done);
      setQuestion(question);
      setTotal(total);
      setMultiplier(multiplier);
      setTotalSpring({ to: { total } });
    })();

    return () => {
      cancelled = true;
    };
  }, [gameKey, setQuestion, setDone, setTotal, setMultiplier]);

  const handleAnswer = useCallback(
    async (answer) => {
      try {
        setLoadingAnswer(answer);
        trackGTMEvent('quiz-answer');
        const {
          data: { explanation, nextQuestion, total, multiplier },
        } = await getFirebase()
          ?.functions('us-central1')
          .httpsCallable('answer')({ questionId: question.id, answer });

        setTotal(total);
        setMultiplier(multiplier);

        if (explanation) {
          setLoadingAnswer(null);
          setExplanation(explanation);
          setWrongAnswers((old) => ({ ...old, [answer]: true }));

          setMultiplierSpring({
            to: [{ transform: `scale(0.5)` }, { transform: `scale(1)` }],
          });
          trackGTMEvent('quiz-answer-wrong');
          return;
        }
        setCorrectAnswers((old) => ({ ...old, [answer]: true }));
        setMultiplierSpring({
          to: [{ transform: `scale(1.5)` }, { transform: `scale(1)` }],
        });
        setTotalSpring({
          to: [{ transform: `scale(1.5)`, total }, { transform: `scale(1)` }],
        });

        setLoadingAnswer('SUCCESS_ANIMATION_PLAYING');
        trackGTMEvent('quiz-answer-correct');
        await wait(SUCCESS_ANIMATION_DURATION);
        setLoadingAnswer(null);

        if (nextQuestion) {
          setExplanation(undefined);
          setQuestion(nextQuestion);
          setWrongAnswers({});
          setCorrectAnswers({});
        } else {
          setExplanation(undefined);
          setDone(true);
          setQuestion(undefined);
          setWrongAnswers({});
          setCorrectAnswers({});
          trackGTMEvent('quiz-done');
        }
      } finally {
        setLoadingAnswer(null);
      }
    },
    [question, setTotal, setMultiplier, setExplanation]
  );

  const handlePlayAgain = useCallback(async () => {
    await getFirebase()?.auth().signOut();
    await getFirebase()?.auth().signInAnonymously();
    setGameKey((key) => key + 1);
  }, [setGameKey]);

  const [mainSectionRef, { height: mainSectionHeight }] = useMeasure();

  const mainSectionTransition = useTransition(
    done ? 'done' : question,
    (it) => {
      if (typeof it === 'string') return it;
      return it?.id;
    },
    {
      from: {
        transform: 'translate3D(0, 100vh, 0)',
        height: `0px`,
        opacity: 0,
      },
      enter: {
        transform: 'translate3D(0, 0vh, 0)',
        height: `${mainSectionHeight}px`,
        opacity: 1,
      },
      update: {
        height: `${mainSectionHeight}px`,
      },
      leave: {
        transform: 'translate3D(0, -100vh, 0)',
        height: `0px`,
        opacity: 0,
      },
    }
  );

  const answersTransitions = useTransition(
    question?.answers || [],
    (answer) => answer,
    {
      from: {
        transform: 'translate3D(-100vw, 0, 0)',
        opacity: 0,
        shake: 0,
        flex: 0,
      },
      enter: {
        transform: 'translate3D(0, 0, 0)',
        opacity: 1,
        flex: 1000,
      },
      leave: {
        transform: 'translate3D(-100vw, 0, 0)',
        opacity: 0,
        flex: 0,
      },
      update: (answer) => ({
        backgroundColor:
          loadingAnswer === answer
            ? '#2f4500'
            : wrongAnswers[answer]
            ? 'gray'
            : '#5b7024',
        shake: wrongAnswers[answer] ? 100 : 0,
      }),
    }
  );

  const answerStatesTransitions = useTransition(
    (question?.answers || []).map((answer) => ({
      answer,
      state:
        loadingAnswer === answer
          ? 'loading'
          : wrongAnswers[answer]
          ? 'wrong'
          : correctAnswers[answer]
          ? 'correct'
          : null,
    })),
    JSON.stringify,
    {
      from: {
        transform: 'translate3D(200%, 0, 0)',
        opacity: 0,
        width: 0,
      },
      enter: {
        transform: 'translate3D(0, 0, 0)',
        opacity: 1,
        width: 16,
      },
      leave: {
        transform: 'translate3D(200%, 0, 0)',
        opacity: 0,
        width: 0,
      },
    }
  );

  const modalPlaceholderSize = useSpring({
    height: explanation ? '60px' : '0px',
  });
  const modalTransition = useTransition(explanation, null, {
    from: {
      transform: 'translate3D(0, 150%, 0)',
      opacity: '0',
      paddingTop: '0rem',
      paddingBottom: '0rem',
    },
    enter: {
      transform: 'translate3D(0, 0%, 0)',
      opacity: '1',
      paddingTop: '1.5rem',
      paddingBottom: '1.5rem',
    },
    leave: {
      transform: 'translate3D(0, 150%, 0)',
      opacity: '0',
      paddingTop: '0rem',
      paddingBottom: '0rem',
    },
  });

  const [loadingDots] = useSpring(() => ({
    from: { num: 0 },

    to: async (next) => {
      while (1) {
        await next({ num: 0 });
        await next({ num: 3 });
      }
    },
  }));

  const [multiplierSpring, setMultiplierSpring] = useSpring(() => ({
    from: { transform: `scale(1)` },
  }));
  const [totalSpring, setTotalSpring] = useSpring(() => ({
    from: { transform: `scale(1)`, total },
  }));

  function renderMainSection() {
    return mainSectionTransition.map(({ item, key, props }) =>
      item === 'done' ? (
        <animated.div key={key} style={props}>
          <div ref={mainSectionRef}>
            <animated.div className="main-section expanded flex flex-col justify-around">
              <FontAwesomeIcon
                icon={faTrophy}
                className="jumbo-icon text-accent"
              />
              <h2 className="text-4xl">Congratulations!</h2>

              <p className="text-xl font-bold mb-4">Your contribution:</p>

              <p className="text-3xl font-bold  mb-4">
                <FontAwesomeIcon icon={faBrain} className="mr-4" />
                <strong className="text-accent">{total}</strong>
              </p>

              <p className="black font-bold text-center">
                You made the world
                <br />
                <strong className="text-accent">
                  {formatNumber(
                    (total / ((globalTotalPoints ?? total) - total)) * 100
                  )}
                  % safer
                </strong>{' '}
                online!
              </p>
            </animated.div>
          </div>
        </animated.div>
      ) : (
        <animated.div key={key} style={props}>
          <div ref={mainSectionRef}>
            <div className="extra-section flex flex-row justify-between items-center text-xl">
              <animated.div style={multiplierSpring}>
                X{multiplier}
              </animated.div>
              <div className=" font-emp">your score</div>
              <div>
                <animated.span style={totalSpring}>
                  {totalSpring.total.interpolate(Math.floor)}
                </animated.span>
                <FontAwesomeIcon icon={faBrain} className="ml-4" />
              </div>
            </div>
            <animated.div className="main-section expanded">
              {item ? (
                <p
                  className="text-xl"
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              ) : (
                <p className="text-xl">
                  Finding the perfect question
                  <animated.span>
                    {loadingDots.num.interpolate((v) => {
                      return new Array(Math.round(v)).fill('.').join('');
                    })}
                  </animated.span>
                </p>
              )}
            </animated.div>
          </div>
        </animated.div>
      )
    );
  }

  return (
    <>
      {renderMainSection()}

      <div className="footer-section expanded">
        {done ? (
          <>
            <div style={{ flex: 1 }} className="footer-section expanded">
              <div className="restart-game-tip">
                <span>want a better score?</span>
                <Arrow className="start-arrow" />
              </div>
              <button
                className="block btn btn-accent btn-large text-center w-full mb-4"
                onClick={() => handlePlayAgain()}
              >
                Play again
              </button>
              <button
                className="block btn btn-secondary text-center w-full mb-4"
                onClick={openSupportModal}
              >
                Support me
              </button>
            </div>
          </>
        ) : (
          <>
            {answersTransitions.map(({ item: answer, key, props }) => (
              <animated.div
                key={key}
                className="w-full flex items-center overflow-hidden"
                style={{
                  transform: props.transform,
                  opacity: props.opacity,
                  flex: props.flex
                    .interpolate(Math.floor)
                    .interpolate((flex) => `${flex} ${1000 - flex} 33%`),
                }}
              >
                <animated.button
                  className="block btn btn-secondary no-disable w-full mb-4 flex flex-row items-center"
                  style={{
                    minHeight: 64,
                    backgroundColor: props.backgroundColor,
                    transform: props.shake
                      .interpolate(
                        [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                        [0, -1, 2, -4, 4, -4, 4, -4, 2, -1, 0]
                      )
                      .interpolate((deg) => `rotate(${deg * 4}deg)`),
                  }}
                  onClick={() => handleAnswer(answer)}
                  disabled={
                    loadingAnswer ||
                    wrongAnswers[answer] ||
                    correctAnswers[answer]
                  }
                >
                  <span className="flex-1 text-center">{answer}</span>
                  {answerStatesTransitions
                    .filter(({ item }) => item.answer === answer)
                    .map(({ item, key, props }) => (
                      <animated.div key={key} style={props}>
                        {item.state === 'loading' && (
                          <FontAwesomeIcon
                            className="animate-spin"
                            icon={faSpinner}
                          />
                        )}
                        {item.state === 'wrong' && (
                          <FontAwesomeIcon icon={faTimesCircle} />
                        )}
                        {item.state === 'correct' && (
                          <FontAwesomeIcon icon={faCheckCircle} />
                        )}
                      </animated.div>
                    ))}
                </animated.button>
              </animated.div>
            ))}
            <animated.div style={modalPlaceholderSize} />
          </>
        )}
      </div>

      {modalTransition.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className="modal-section text-center"
            >
              {explanation}
            </animated.div>
          )
      )}
    </>
  );
};

export default Quiz;
