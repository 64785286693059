import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React, { useCallback, useEffect, useState } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { animated, useTransition } from 'react-spring';
import { useMeasure } from 'react-use';

import { Layout, SEO } from '../../components';
import Quiz from '../../components/quiz';
import GlobalStatus from '../../components/quiz/GlobalStatus';
import SupportMe from '../../components/quiz/SupportMe';
import Arrow from '../../images/quiz/arrow.inline.svg';
import getFirebase from '../../lib/firebase';
import { trackGTMEvent } from '../../lib/gtm';
import './style.scss';

const QuizPage = ({ data: { headerImage } }) => {
  const [showGame, setShowGame] = useState(false);
  const [showGameError, setShowGameError] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [globalResult, globalResultLoading, globalResultError] = useDocument(
    getFirebase()?.firestore().doc('results/cybersecurity-global')
  );

  const handleStartGame = useCallback(async () => {
    let firebase = getFirebase();
    if (!firebase) {
      setShowGameError(true);
      return;
    }
    try {
      await firebase.auth().signInAnonymously();
      setShowGame(true);
      trackGTMEvent('quiz-start');
    } catch (ex) {
      trackGTMEvent('quiz-anonymous-auth-failed');
      setShowGameError(true);
    }
  }, []);

  const [containerRef, { width: containerWidth }] = useMeasure();

  useEffect(() => {
    if (containerWidth >= 639 && window.scrollY === 0) {
      window.scrollTo(0, 48);
    }
  }, [containerWidth]);

  const backdropTransition = useTransition(
    showSupportModal || showHelpModal,
    null,
    {
      from: {
        pointerEvents: 'none',
        backgroundColor: 'rgba(182, 202, 219, 0)',
      },
      enter: {
        pointerEvents: 'initial',
        backgroundColor: 'rgba(182, 202, 219, 0.75)',
      },
      leave: {
        pointerEvents: 'none',
        backgroundColor: 'rgba(182, 202, 219, 0)',
      },
    }
  );

  const modalTransition = useTransition(
    showSupportModal ? 'support' : showHelpModal ? 'help' : null,
    null,
    {
      from: {
        transform: 'translate3D(0, 150%, 0)',
        opacity: '0',
        paddingTop: '0rem',
        paddingBottom: '0rem',
      },
      enter: {
        transform: 'translate3D(0, 0%, 0)',
        opacity: '1',
        paddingTop: '1.5rem',
        paddingBottom: '1.5rem',
      },
      leave: {
        transform: 'translate3D(0, 150%, 0)',
        opacity: '0',
        paddingTop: '0rem',
        paddingBottom: '0rem',
      },
    }
  );

  const mainSectionTransition = useTransition(
    !showGame && !showGameError,
    null,
    {
      from: {
        transform: 'translate3D(0, 100vh, 0)',
        flex: 2000,
        height: '100%',
        paddingTop: 48,
        paddingBottom: 48,
      },
      enter: {
        transform: 'translate3D(0, 0%, 0)',
      },
      leave: {
        transform: 'translate3D(0, -100vh, 0)',
        flex: 0,
        height: '0%',
        paddingTop: 0,
        paddingBottom: 0,
      },
    }
  );

  function renderStartGame() {
    return (
      <>
        {mainSectionTransition.map(
          ({ item, key, props }) =>
            item && (
              <animated.div key={key} className="main-section" style={props}>
                <div className="start-game-tip">
                  <span>play to learn</span>
                  <Arrow className="start-arrow" />
                </div>
                <button
                  className="block btn btn-accent btn-large text-center w-4/5 mb-4"
                  onClick={handleStartGame}
                >
                  Start the quiz
                </button>
                <button
                  className="block btn btn-secondary btn-md text-center w-4/5 mb-4"
                  onClick={() => {
                    setShowHelpModal(true);

                    trackGTMEvent('quiz-help-modal');
                  }}
                >
                  What is this?
                </button>
                <button
                  className="block btn btn-secondary btn-md text-center w-4/5"
                  onClick={() => {
                    setShowSupportModal(true);

                    trackGTMEvent('quiz-support-modal');
                  }}
                >
                  Support me
                </button>
              </animated.div>
            )
        )}
        <div className="footer-section" />
      </>
    );
  }

  function renderStartGameError() {
    return (
      <>
        <p>
          There seems to be an error starting the game. Please refresh the page
          and try again.
        </p>
      </>
    );
  }

  function renderGame() {
    return (
      <Quiz
        globalTotalPoints={globalResult?.data().totalPoints}
        openHelpModal={() => setShowHelpModal(true)}
        openSupportModal={() => setShowSupportModal(true)}
      />
    );
  }

  function renderModals() {
    return (
      <>
        {backdropTransition.map(
          ({ item, key, props }) =>
            item && (
              <animated.div
                key={key}
                className="backdrop"
                style={props}
                onClick={() => {
                  setShowSupportModal(false);
                  setShowHelpModal(false);
                }}
              />
            )
        )}

        {modalTransition.map(({ item, key, props }) =>
          item === 'support' ? (
            <animated.div
              key={key}
              className="modal-section flex flex-col"
              style={props}
            >
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => setShowSupportModal(false)}
                className="ml-auto -mr-6"
              />
              <SupportMe
                nordVPNLink="https://go.nordvpn.net/aff_c?offer_id=15&aff_id=45264&url_id=1288&source=support-modal"
                nordPassLink="https://go.nordpass.io/aff_c?offer_id=488&aff_id=45264&url_id=9356&source=support-modal"
              />
            </animated.div>
          ) : item === 'help' ? (
            <animated.div
              key={key}
              className="modal-section flex flex-col"
              style={props}
            >
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => setShowHelpModal(false)}
                className="ml-auto -mr-6"
              />
              <h1 className="mb-2 mt-6">What is this?</h1>
              <p>
                The cyber-security quiz is a game with questions related to
                personal internet safety that helps you learn.
              </p>

              <h2 className="mb-2 mt-6">Points</h2>
              <p>
                The points you earn by answering correctly contribute to a
                global knowledge pool. The more you play, the safer the entire
                world is online!
              </p>

              <h2 className="mb-2 mt-6">Multiplier</h2>
              <p>
                Correctly answering a question will increase the points for the
                next question. But be careful! If you miss an answer, the
                multiplier resets. The more questions you answer correctly
                without missing any, the greater your score.
              </p>
            </animated.div>
          ) : null
        )}
      </>
    );
  }

  return (
    <Layout bodyClass="quiz-page" headerClass="quiz-header">
      <SEO title="Cyber security Live Quiz" url="cybersecurity-live-quiz" />

      <BackgroundImage
        Tag="section"
        className=""
        fluid={headerImage.childImageSharp.fluid}
      >
        <div className="quiz-container" ref={containerRef}>
          <GlobalStatus
            minimal={showGame}
            points={globalResult?.data()?.totalPoints}
            people={globalResult?.data()?.totalPeople}
            handleGoHome={() => setShowGame(false)}
          />

          {showGameError && renderStartGameError()}
          {renderStartGame()}
          {showGame && renderGame()}

          {renderModals()}
        </div>
      </BackgroundImage>
    </Layout>
  );
};

export default QuizPage;
export const query = graphql`
  query QuizPageQuery {
    headerImage: file(
      relativePath: { eq: "home/s-b-vonlanthen-BrbogxYe7FE-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
