import {
  faBrain,
  faFlagCheckered,
  faHome,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { animated, useSpring, useTransition } from 'react-spring';
import { useMeasure } from 'react-use';

import EarthClipping from '../../images/quiz/planet-earth.clipping.inline.svg';
import { formatInteger } from '../../lib/spring';

const GlobalStatus = ({
  minimal = false,
  points = 0,
  people = 0,
  handleGoHome,
}) => {
  const [target, setTarget] = useState(1);
  const targetReach = useMemo(() => (points / target) * 100, [points, target]);
  useEffect(() => {
    setTarget(Math.pow(10, Math.ceil(Math.log10(points))));
  }, [points, setTarget]);

  const animatedNumbers = useSpring({ points, people, target });

  const [headerRef, { height: headerHeight }] = useMeasure();
  const headerTransition = useTransition(!minimal, null, {
    from: {
      transform: `translate3D(0, -300px, 0)`,
      marginBottom: '0rem',
      marginTop: '0rem',
      height: '0px',
    },
    enter: {
      transform: `translate3D(0, 0px, 0)`,
      marginBottom: '1.5rem',
      marginTop: '1.5rem',
      height: `${headerHeight ?? 100}px`,
    },
    update: {
      height: `${headerHeight ?? 100}px`,
    },
    leave: {
      transform: `translate3D(0, -300px, 0)`,
      marginBottom: '0rem',
      marginTop: '0rem',
      height: '0px',
    },
  });

  const containerResize = useSpring({
    flex: minimal ? 1000 : 2000,
    paddingBottom: minimal ? '0rem' : '3rem',
  });

  const targetFlyOut = useSpring({
    transform: `translate3D(${minimal ? -300 : 0}px, 0,0)`,
    opacity: minimal ? 0 : 1,

    display: minimal ? 'none' : 'flex',

    flex: minimal ? 0 : 1000,
    width: minimal ? '0%' : '50%',
    marginRight: minimal ? '0rem' : '2rem',
  });
  const homeFlyIn = useSpring({
    transform: `translate3D(${minimal ? 0 : -300}px, 0,0)`,
    opacity: minimal ? 1 : 0,
    flex: minimal ? 1000 : 0,
    width: minimal ? '50%' : '0%',
  });

  const targetFill = useSpring({ fill: isNaN(targetReach) ? 0 : targetReach });

  const scoreResize = useSpring({
    fontSize: minimal ? '1.25rem' : '2.25rem',
  });

  const scoreReposition = useSpring({
    marginRight: minimal ? '5em' : '0em',
    alignItems: minimal ? 'flex-start' : 'flex-end',
    flex: 2,
  });

  const scoreIconReposition = useSpring({
    flexDirection: minimal ? 'row-reverse' : 'row',
  });

  return (
    <animated.div
      className={clsx('global-status', minimal && 'minimal')}
      style={containerResize}
    >
      {headerTransition.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              <h1 ref={headerRef} className="text-5xl m-0">
                cyber-security
                <br />
                knowledge
              </h1>
            </animated.div>
          )
      )}

      <div className="flex flex-row items-center">
        <animated.div
          className="target text-white flex flex-col"
          style={targetFlyOut}
        >
          <EarthClipping />
          <animated.div
            className="progress"
            style={{
              background: targetFill.fill.interpolate(
                (fill) =>
                  `conic-gradient(#f9a825 0% ${fill}%, rgba(182,202,219,.2) ${fill}% 100%)`
              ),
            }}
          />

          <p className="milestone">
            <FontAwesomeIcon icon={faFlagCheckered} className="mr-4" />
            <animated.span>
              {animatedNumbers.target.interpolate(formatInteger)}
            </animated.span>
          </p>
        </animated.div>

        <animated.div className="home" onClick={handleGoHome} style={homeFlyIn}>
          <FontAwesomeIcon icon={faHome} />
        </animated.div>

        <animated.div style={scoreReposition} className="global-progress">
          <animated.p
            className="text-white font-bold"
            style={{ ...scoreResize, ...scoreIconReposition }}
          >
            <animated.span>
              {animatedNumbers.points.interpolate(formatInteger)}
            </animated.span>
            <FontAwesomeIcon icon={faBrain} className="ml-4" />
          </animated.p>
          <animated.p
            className="text-xl text-white font-bold"
            style={scoreIconReposition}
          >
            <animated.span>
              {animatedNumbers.people.interpolate(formatInteger)}
            </animated.span>
            <FontAwesomeIcon icon={faUsers} className="ml-4" />
          </animated.p>
        </animated.div>
      </div>
    </animated.div>
  );
};

export default GlobalStatus;
